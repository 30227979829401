const sendEmail = () => {
  console.log("hey whatsup");

  document.querySelectorAll(".email-form").forEach((el) => {
    el.addEventListener("submit", async (ev) => {
      ev.preventDefault();
      const input = ev.target.querySelector('input[type="email"]');
      const btn = ev.target.querySelector("button.send-email");
      const alertSuccess = ev.target.parentElement.parentElement.querySelector('.alert-success')
      const alertDanger = ev.target.parentElement.parentElement.querySelector('.alert-danger')
      
      input.disabled = true;
      btn.disabled = true;
      alertSuccess.classList.add('d-none')
      alertDanger.classList.add('d-none')


      try {
        gtag('event', 'manual_event_SUBMIT_LEAD_FORM', {}); 

        await fetch("https://emails.friz.xyz/CostMyRecipes/emails", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: input.value,
          }),
        });
        alertSuccess.classList.remove('d-none')
      } catch (error) {
        alertDanger.classList.remove('d-none')
      }

      input.disabled = false;
      btn.disabled = false;
    });
  });
};

export default sendEmail;
